import React, {useState, useRef, useEffect} from 'react'
import {TextField,TableRow, TableCell, InputAdornment } from '@material-ui/core/';
import { useForm } from "react-hook-form";
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

export default function AddonsItem({passedData, handleAddOnValueChange, handleItemRemoval, handleAddOnTitleChange}) {

    const [addonItemValue, setAddonitemValue] = useState(0)

    useEffect(() => {
      if(passedData) {
        setAddonitemValue(passedData.addon_quantity * passedData.addon_rate)
      }
    }, [passedData])

    const qunatityRef = useRef();
    const rateRef = useRef();

    const {  register, getValues, handleSubmit, control, setValue, errors } = useForm();

    const handleItemTotalChange = () => {
        setAddonitemValue(qunatityRef.current.value * rateRef.current.value)
       // handleAddOnValueChange((qunatityRef.current.value * rateRef.current.value, passedData.itemId) , fieldType)
       handleAddOnValueChange(qunatityRef.current.value, rateRef.current.value, passedData.itemId)
       
      //  console.log(passedData.itemId)
    }

    return (
        
        <TableRow>
           <TableCell style={{maxWidth: "20px"}}>
           <IconButton color="primary" aria-label="remove item" onClick={() => {
             handleItemRemoval(passedData.itemId)
                   // handleRemoveItemClick()
                }}>
                   <DeleteOutlineIcon />
                    </IconButton>
           </TableCell>
            <TableCell style={{minWidth: "100px"}}>
              
            <TextField label="Title" variant="outlined"
          name="event_addon_title" id="event_addon_title"
          size="small"
          defaultValue={passedData.addon_title}
          fullWidth
          register={register}
          onChange={(e) => {
            handleAddOnTitleChange(e.currentTarget.value, passedData.itemId)
            
          }}
          />
            </TableCell>
           
            <TableCell align="right">
            <TextField label="Qty" variant="outlined"
          name="addon_quantity" id="addon_quantity"
          size="small"
          defaultValue={passedData.addon_quantity}
          type="number"
          {...register("addon_quantity")}
          inputRef={qunatityRef}
         
          onChange={(e) => {
            handleItemTotalChange()
          }}
          inputProps={
              {min: "1",
              max: "1000"}
          }
          />
            </TableCell>
            <TableCell align="right">
            <TextField label="Rate" variant="outlined"
          name="addon_rate" id="addon_rate"
          size="small"
          defaultValue={passedData.addon_rate}
          type="number"
          {...register("addon_rate")}
          inputRef={rateRef}
          onChange={(e) => {
            handleItemTotalChange()
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
           
          }}
          inputProps={
            {min: "1",
            max: "1000"}
        }
          />
            </TableCell>
            <TableCell align="left">
${parseFloat(addonItemValue).toFixed(2)}
            </TableCell>
            </TableRow>
    )
}
