import React, { useState, useRef, useEffect } from 'react'
import {Grid, Button, TextField, Select, FormControl,MenuItem , InputLabel, Checkbox, FormGroup, FormControlLabel,FormLabel,
    Typography, Divider, Table, TableContainer, TableRow, TableCell, TableHead,TableBody, InputAdornment, Card, Paper, CardActions, CardContent, Box, Switch,Snackbar, SnackbarContent, IconButton   } from '@material-ui/core/';

    import { Link, useHistory, withRouter } from "react-router-dom";
    import axios from "axios";
    import PropTypes from 'prop-types';


//import AuthService from "../services/auth.service";

// const required = (value) => {
//   if (!value) {
//     return (
//       <div className="alert alert-danger" role="alert">
//         This field is required!
//       </div>
//     );
//   }
// };

async function loginUser( username,
  password) {
  return  axios
  .post("https://ps.torontograndcentre.com/tgc_back/login.php", {
   // .post("http://localhost/tgc_back/login.php", {
      
          email:username,
          password:password
          
  })
    .then(data => 
      data.data
      )
 }
 

export default function Login({ setToken }) {
  useEffect(() => {
    document.title = "TG Login"
  }, [])

  
   let history = useHistory();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = async e => {
   // console.log(e)
    e.preventDefault();
    const token = await loginUser(
      username,
      password);
      console.log(token)

      if(token.message === "Successful login.") {
        //function setToken(userToken) {
          sessionStorage.setItem('token', JSON.stringify(token));
      //  }

        //setToken(token);
    //    window.location.reload();
      //  history.push("/dashboard");
      window.location.replace("/")
      } else {
        alert("Login failed!")
      }
  
  //   setMessage("");
  //   setLoading(true);

  //  // form.current.validateAll();
  //  AuthService.login(username, password).then(
  //   () => {
  //    history.replace("/dashboard");
  //   //  window.location.reload();
  //   },
  //   (error) => {
  //     const resMessage =
  //       (error.response &&
  //         error.response.data &&
  //         error.response.data.message) ||
  //       error.message ||
  //       error.toString();

  //     setLoading(false);
  //     setMessage(resMessage);
  //   }
  // );
    // if (checkBtn.current.context._errors.length === 0) {
    //   AuthService.login(username, password).then(
    //     () => {
    //       props.history.push("/profile");
    //       window.location.reload();
    //     },
    //     (error) => {
    //       const resMessage =
    //         (error.response &&
    //           error.response.data &&
    //           error.response.data.message) ||
    //         error.message ||
    //         error.toString();

    //       setLoading(false);
    //       setMessage(resMessage);
    //     }
    //   );
    // } else {
    //   setLoading(false);
    // }
  };

    return (
      
        <Grid 
        container
        spacing={1}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '500px' }}
        >
           <Grid item xs={2}>
          <Card
          style={{
            minWidth: "300px"
          }}
          >
           
            <CardContent>
            <form 
            onSubmit={(e) => handleLogin(e)}
            >
            <TextField id="outlined-basic" label="Email"
            onChange={onChangeUsername}
            fullWidth
            size="small"
            style={{
              marginBottom: "15px"
            }}
            variant="outlined" />

            <TextField id="outlined-basic" label="Password" 
            style={{
              marginBottom: "15px"
            }}
            onChange={onChangePassword}
            fullWidth
            size="small"
            type="password"
            variant="outlined" />

            <Button 
            variant="contained"
            color="primary"
            fullWidth
            // onClick={() => {
                
            // }}
            disableElevation
            type="submit"
            >
                Login
            </Button>
            </form>
            </CardContent>
            </Card>
            </Grid>

        </Grid>
    )
}
Login.propTypes = {
  setToken: PropTypes.func.isRequired
}