import logo from './logo.svg';
// import './App.css';
import { Route, Link, Redirect, Switch, BrowserRouter as Router } from "react-router-dom";
import Bookings from './bookings/Bookings';
import "./app_style.css";
import Sidebar from "./Sidebar";
import ViewBooking from './bookings/ViewBooking';
import NewBooking from './bookings/NewBooking';
import Dashboard from './Dashboard';
import Login from './gen_comps/Login';
import Calendar from './calendar/Calendar';
import QuotesList from './quotes/QuotesList';
import useToken from './gen_comps/useToken';

import CanvasTool from './bookings/CanvasTool';

//import UserService from "./services/user.service";

function setToken(userToken) {
  sessionStorage.setItem('token', JSON.stringify(userToken));
}

function getToken() {
  const tokenString = sessionStorage.getItem('token');
  const userToken = JSON.parse(tokenString);
  return userToken?.token
}


export default function App() {
  const token = getToken();
  //const { token, setToken } = useToken();

  //const { token, setToken } = useToken();

  if(!token) {
    //console.log("Not here")
    return <Login setToken={setToken} />
  }
  //useEffect(() => {
    
  //}, []);
  // let isLoggedIn = false;
  // UserService.getUserBoard().then(
  //   (response) => {
  //     isLoggedIn = true
  //   },
  //   (error) => {
  //     isLoggedIn = false
  //   }
  // )
  
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    token 
      ? <Component {...props} />
      : <Redirect to='/login' />
  )} />

 
)

  return (
    <div className="App">
      
      <Router  basename="/">
      <Switch>
          {/* <Route path="/bookings" exact component={Bookings} /> */}
          {/* <Route path="/bookings/view/:booking_id" exact component={ViewBooking} /> */}
          <Route path="/login" exact>
            <Login />
          </Route>
          <Route path="/" exact>
            <Dashboard />
          </Route>
          {/* <PrivateRoute path='/dashboard' component={Dashboard} />
          <PrivateRoute path='/calendar' component={Calendar} />
          <PrivateRoute path='/bookings' component={Bookings} />
          <PrivateRoute path='/quotes' component={QuotesList} />
          <PrivateRoute path='/view/:booking_id' component={ViewBooking} /> */}
          
          <Route path="/calendar" exact>
            <Calendar />
          </Route>
          <Route path="/bookings/" exact>
            <Bookings />
          </Route>
          <Route path="/quotes/" exact>
            <QuotesList />
          </Route>
          <Route path="/bookings/view/:booking_id" exact>
            <ViewBooking />
          </Route>
          <Route path="/bookings/new" exact component={NewBooking} />
        </Switch>
        </Router>
    </div>
  );
}

//export default App;
