import React, {useState, useRef, useEffect} from 'react';
import {Grid, Button, TextField, Select, FormControl,MenuItem , InputLabel, Checkbox, FormGroup, FormControlLabel,FormLabel,
    Typography, Divider, Table, TableContainer, TableRow, TableCell, Paper, TableHead,TableBody, InputAdornment, Card, CardHeader, CardActions, CardContent, Box, Switch,Snackbar, SnackbarContent   } from '@material-ui/core/';
import Sidebar from './Sidebar';

import { PieChart, Pie, Legend, Cell, Tooltip, ResponsiveContainer, Sector,
    Label, LabelList, BarChart, Bar, CartesianGrid, XAxis, YAxis, LineChart, Line, ComposedChart, Area } from 'recharts';
    import moment from 'moment';

    function randomIntFromInterval(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
      }


   
          
      const TopBookingSourcesData = [
        {
            name: 'Jan', Called: randomIntFromInterval(7500,25000), Emailed: randomIntFromInterval(7500,25000), Been_Here_Before: randomIntFromInterval(7500,25000), EventSource: randomIntFromInterval(7500,25000), WeddingWire: randomIntFromInterval(7500,25000), Facebook: randomIntFromInterval(7500,25000),
            Instagram: randomIntFromInterval(7500,25000), Google: randomIntFromInterval(7500,25000), Referral: randomIntFromInterval(7500,25000)
        },
        {
            name: 'Feb', Called: randomIntFromInterval(7500,25000), Emailed: randomIntFromInterval(7500,25000), Been_Here_Before: randomIntFromInterval(7500,25000), EventSource: randomIntFromInterval(7500,25000), WeddingWire: randomIntFromInterval(7500,25000), Facebook: randomIntFromInterval(7500,25000),
            Instagram: randomIntFromInterval(7500,25000), Google: randomIntFromInterval(7500,25000), Referral: randomIntFromInterval(7500,25000)
        },
        {
            name: 'Mar', Called: randomIntFromInterval(7500,25000), Emailed: randomIntFromInterval(7500,25000), Been_Here_Before: randomIntFromInterval(7500,25000), EventSource: randomIntFromInterval(7500,25000), WeddingWire: randomIntFromInterval(7500,25000), Facebook: randomIntFromInterval(7500,25000),
            Instagram: randomIntFromInterval(7500,25000), Google: randomIntFromInterval(7500,25000), Referral: randomIntFromInterval(7500,25000)
        },
        {
            name: 'Apr', Called: randomIntFromInterval(7500,25000), Emailed: randomIntFromInterval(7500,25000), Been_Here_Before: randomIntFromInterval(7500,25000), EventSource: randomIntFromInterval(7500,25000), WeddingWire: randomIntFromInterval(7500,25000), Facebook: randomIntFromInterval(7500,25000),
            Instagram: randomIntFromInterval(7500,25000), Google: randomIntFromInterval(7500,25000), Referral: randomIntFromInterval(7500,25000)
        },
        {
            name: 'May', Called: randomIntFromInterval(7500,25000), Emailed: randomIntFromInterval(7500,25000), Been_Here_Before: randomIntFromInterval(7500,25000), EventSource: randomIntFromInterval(7500,25000), WeddingWire: randomIntFromInterval(7500,25000), Facebook: randomIntFromInterval(7500,25000),
            Instagram: randomIntFromInterval(7500,25000), Google: randomIntFromInterval(7500,25000), Referral: randomIntFromInterval(7500,25000)
        },
        {
            name: 'Jun', Called: randomIntFromInterval(7500,25000), Emailed: randomIntFromInterval(7500,25000), Been_Here_Before: randomIntFromInterval(7500,25000), EventSource: randomIntFromInterval(7500,25000), WeddingWire: randomIntFromInterval(7500,25000), Facebook: randomIntFromInterval(7500,25000),
            Instagram: randomIntFromInterval(7500,25000), Google: randomIntFromInterval(7500,25000), Referral: randomIntFromInterval(7500,25000)
        },
        {
            name: 'Jul', Called: randomIntFromInterval(7500,25000), Emailed: randomIntFromInterval(7500,25000), Been_Here_Before: randomIntFromInterval(7500,25000), EventSource: randomIntFromInterval(7500,25000), WeddingWire: randomIntFromInterval(7500,25000), Facebook: randomIntFromInterval(7500,25000),
            Instagram: randomIntFromInterval(7500,25000), Google: randomIntFromInterval(7500,25000), Referral: randomIntFromInterval(7500,25000)
        },
        {
            name: 'Aug', Called: randomIntFromInterval(7500,25000), Emailed: randomIntFromInterval(7500,25000), Been_Here_Before: randomIntFromInterval(7500,25000), EventSource: randomIntFromInterval(7500,25000), WeddingWire: randomIntFromInterval(7500,25000), Facebook: randomIntFromInterval(7500,25000),
            Instagram: randomIntFromInterval(7500,25000), Google: randomIntFromInterval(7500,25000), Referral: randomIntFromInterval(7500,25000)
        },
        {
            name: 'Sept', Called: randomIntFromInterval(7500,25000), Emailed: randomIntFromInterval(7500,25000), Been_Here_Before: randomIntFromInterval(7500,25000), EventSource: randomIntFromInterval(7500,25000), WeddingWire: randomIntFromInterval(7500,25000), Facebook: randomIntFromInterval(7500,25000),
            Instagram: randomIntFromInterval(7500,25000), Google: randomIntFromInterval(7500,25000), Referral: randomIntFromInterval(7500,25000)
        },
        {
            name: 'Oct', Called: randomIntFromInterval(7500,25000), Emailed: randomIntFromInterval(7500,25000), Been_Here_Before: randomIntFromInterval(7500,25000), EventSource: randomIntFromInterval(7500,25000), WeddingWire: randomIntFromInterval(7500,25000), Facebook: randomIntFromInterval(7500,25000),
            Instagram: randomIntFromInterval(7500,25000), Google: randomIntFromInterval(7500,25000), Referral: randomIntFromInterval(7500,25000)
        },
        {
            name: 'Nov', Called: randomIntFromInterval(7500,25000), Emailed: randomIntFromInterval(7500,25000), Been_Here_Before: randomIntFromInterval(7500,25000), EventSource: randomIntFromInterval(7500,25000), WeddingWire: randomIntFromInterval(7500,25000), Facebook: randomIntFromInterval(7500,25000),
            Instagram: randomIntFromInterval(7500,25000), Google: randomIntFromInterval(7500,25000), Referral: randomIntFromInterval(7500,25000)
        },
        {
            name: 'Dec', Called: randomIntFromInterval(7500,25000), Emailed: randomIntFromInterval(7500,25000), Been_Here_Before: randomIntFromInterval(7500,25000), EventSource: randomIntFromInterval(7500,25000), WeddingWire: randomIntFromInterval(7500,25000), Facebook: randomIntFromInterval(7500,25000),
            Instagram: randomIntFromInterval(7500,25000), Google: randomIntFromInterval(7500,25000), Referral: randomIntFromInterval(7500,25000)
        },
      ];
    
    const data02 = [
        {
            name: 'Jan', Stevan: randomIntFromInterval(7500,25000), Ali: randomIntFromInterval(7500,25000), Rany: randomIntFromInterval(7500,25000), Evan: randomIntFromInterval(7500,25000), Dani: randomIntFromInterval(7500,25000), Kanny: randomIntFromInterval(7500,25000),
        },
        {
            name: 'Feb', Stevan: randomIntFromInterval(7500,25000), Ali: randomIntFromInterval(7500,25000), Rany: randomIntFromInterval(7500,25000), Evan: randomIntFromInterval(7500,25000), Dani: randomIntFromInterval(7500,25000), Kanny: randomIntFromInterval(7500,25000),        },
        {
            name: 'Mar', Stevan: randomIntFromInterval(7500,25000), Ali: randomIntFromInterval(7500,25000), Rany: randomIntFromInterval(7500,25000), Evan: randomIntFromInterval(7500,25000), Dani: randomIntFromInterval(7500,25000), Kanny: randomIntFromInterval(7500,25000),        },
        {
            name: 'Apr', Stevan: randomIntFromInterval(7500,25000), Ali: randomIntFromInterval(7500,25000), Rany: randomIntFromInterval(7500,25000), Evan: randomIntFromInterval(7500,25000), Dani: randomIntFromInterval(7500,25000), Kanny: randomIntFromInterval(7500,25000),        },
        {
            name: 'May', Stevan: randomIntFromInterval(7500,25000), Ali: randomIntFromInterval(7500,25000), Rany: randomIntFromInterval(7500,25000), Evan: randomIntFromInterval(7500,25000), Dani: randomIntFromInterval(7500,25000), Kanny: randomIntFromInterval(7500,25000),        },
        {
            name: 'Jun', Stevan: randomIntFromInterval(7500,25000), Ali: randomIntFromInterval(7500,25000), Rany: randomIntFromInterval(7500,25000), Evan: randomIntFromInterval(7500,25000), Dani: randomIntFromInterval(7500,25000), Kanny: randomIntFromInterval(7500,25000),        },
        {
            name: 'Jul', Stevan: randomIntFromInterval(7500,25000), Ali: randomIntFromInterval(7500,25000), Rany: randomIntFromInterval(7500,25000), Evan: randomIntFromInterval(7500,25000), Dani: randomIntFromInterval(7500,25000), Kanny: randomIntFromInterval(7500,25000),        },
        {
            name: 'Aug', Stevan: randomIntFromInterval(7500,25000), Ali: randomIntFromInterval(7500,25000), Rany: randomIntFromInterval(7500,25000), Evan: randomIntFromInterval(7500,25000), Dani: randomIntFromInterval(7500,25000), Kanny: randomIntFromInterval(7500,25000),        },
        {
            name: 'Sept', Stevan: randomIntFromInterval(7500,25000), Ali: randomIntFromInterval(7500,25000), Rany: randomIntFromInterval(7500,25000), Evan: randomIntFromInterval(7500,25000), Dani: randomIntFromInterval(7500,25000), Kanny: randomIntFromInterval(7500,25000),        },
        {
            name: 'Oct', Stevan: randomIntFromInterval(7500,25000), Ali: randomIntFromInterval(7500,25000), Rany: randomIntFromInterval(7500,25000), Evan: randomIntFromInterval(7500,25000), Dani: randomIntFromInterval(7500,25000), Kanny: randomIntFromInterval(7500,25000),        },
        {
            name: 'Nov', Stevan: randomIntFromInterval(7500,25000), Ali: randomIntFromInterval(7500,25000), Rany: randomIntFromInterval(7500,25000), Evan: randomIntFromInterval(7500,25000), Dani: randomIntFromInterval(7500,25000), Kanny: randomIntFromInterval(7500,25000),        },
        {
            name: 'Dec', Stevan: randomIntFromInterval(7500,25000), Ali: randomIntFromInterval(7500,25000), Rany: randomIntFromInterval(7500,25000), Evan: randomIntFromInterval(7500,25000), Dani: randomIntFromInterval(7500,25000), Kanny: randomIntFromInterval(7500,25000),        },
      ];

      const monthlyChartData = [
        {
          name: 'Jan', Stevan: randomIntFromInterval(45,70), Ali: randomIntFromInterval(45,70), Rany: randomIntFromInterval(45,70), Evan: randomIntFromInterval(45,70), Dani: randomIntFromInterval(45,70), Kanny: randomIntFromInterval(45,70), Monthly_Average: randomIntFromInterval(45,70),
        },
        {
            name: 'Feb', Stevan: randomIntFromInterval(45,70), Ali: randomIntFromInterval(45,70), Rany: randomIntFromInterval(45,70), Evan: randomIntFromInterval(45,70), Dani: randomIntFromInterval(45,70), Kanny: randomIntFromInterval(45,70), Monthly_Average: randomIntFromInterval(45,70),
        },
        {
            name: 'Mar', Stevan: randomIntFromInterval(45,70), Ali: randomIntFromInterval(45,70), Rany: randomIntFromInterval(45,70), Evan: randomIntFromInterval(45,70), Dani: randomIntFromInterval(45,70), Kanny: randomIntFromInterval(45,70), Monthly_Average: randomIntFromInterval(45,70),
        },
        {
            name: 'Apr', Stevan: randomIntFromInterval(45,70), Ali: randomIntFromInterval(45,70), Rany: randomIntFromInterval(45,70), Evan: randomIntFromInterval(45,70), Dani: randomIntFromInterval(45,70), Kanny: randomIntFromInterval(45,70), Monthly_Average: randomIntFromInterval(45,70),
        },
        {
            name: 'May', Stevan: randomIntFromInterval(45,70), Ali: randomIntFromInterval(45,70), Rany: randomIntFromInterval(45,70), Evan: randomIntFromInterval(45,70), Dani: randomIntFromInterval(45,70), Kanny: randomIntFromInterval(45,70), Monthly_Average: randomIntFromInterval(45,70),
        },
        {
            name: 'Jun', Stevan: randomIntFromInterval(45,70), Ali: randomIntFromInterval(45,70), Rany: randomIntFromInterval(45,70), Evan: randomIntFromInterval(45,70), Dani: randomIntFromInterval(45,70), Kanny: randomIntFromInterval(45,70), Monthly_Average: randomIntFromInterval(45,70),
        },
        {
            name: 'Jul', Stevan: randomIntFromInterval(45,70), Ali: randomIntFromInterval(45,70), Rany: randomIntFromInterval(45,70), Evan: randomIntFromInterval(45,70), Dani: randomIntFromInterval(45,70), Kanny: randomIntFromInterval(45,70), Monthly_Average: randomIntFromInterval(45,70),
        },
        {
            name: 'Aug', Stevan: randomIntFromInterval(45,70), Ali: randomIntFromInterval(45,70), Rany: randomIntFromInterval(45,70), Evan: randomIntFromInterval(45,70), Dani: randomIntFromInterval(45,70), Kanny: randomIntFromInterval(45,70), Monthly_Average: randomIntFromInterval(45,70),
        },
        {
            name: 'Sept', Stevan: randomIntFromInterval(45,70), Ali: randomIntFromInterval(45,70), Rany: randomIntFromInterval(45,70), Evan: randomIntFromInterval(45,70), Dani: randomIntFromInterval(45,70), Kanny: randomIntFromInterval(45,70), Monthly_Average: randomIntFromInterval(45,70),
        },
        {
            name: 'Oct', Stevan: randomIntFromInterval(45,70), Ali: randomIntFromInterval(45,70), Rany: randomIntFromInterval(45,70), Evan: randomIntFromInterval(45,70), Dani: randomIntFromInterval(45,70), Kanny: randomIntFromInterval(45,70), Monthly_Average: randomIntFromInterval(45,70),
        },
        {
            name: 'Nov', Stevan: randomIntFromInterval(45,70), Ali: randomIntFromInterval(45,70), Rany: randomIntFromInterval(45,70), Evan: randomIntFromInterval(45,70), Dani: randomIntFromInterval(45,70), Kanny: randomIntFromInterval(45,70), Monthly_Average: randomIntFromInterval(45,70),
        },
        {
            name: 'Dec', Stevan: randomIntFromInterval(45,70), Ali: randomIntFromInterval(45,70), Rany: randomIntFromInterval(45,70), Evan: randomIntFromInterval(45,70), Dani: randomIntFromInterval(45,70), Kanny: randomIntFromInterval(45,70), Monthly_Average: randomIntFromInterval(45,70),
        },
      ];


      function timeOfDayDisplay () {
        const  morning = moment('08:00am', 'hh:mma');
        const noon = moment('11:59am', 'hh:mma');
        const evening = moment('05:59pm', 'hh:mma');
        const currentTime = moment();

        if(currentTime.isBefore(noon) && currentTime.isAfter(morning))
            {
            return "morning"
            } else if(currentTime.isBefore(evening) && currentTime.isAfter(noon)) {
                return "afternoon"
            } else {
                return "evening"
            }
      }
      
      
export default function Dashboard() {
    useEffect(() => {
        document.title = "TG Dashboard"
      }, [])

      const currentUser = JSON.parse(sessionStorage.getItem('token'));

    return (
        <Grid
        container>
            <Grid item xs={2}
                style={{maxWidth: "260px"}}
            >
                <Sidebar />
            </Grid>
            <Grid item xs={10}
                style={{paddingLeft: "10px"}}
            >
                <Typography
                style={{
                    padding: "15px 0px 0px 0px"
                }}
                variant="h6">
                    TG Dashboard
                </Typography>
                <Typography
                style={{
                    padding: "0px 0px 15px 0px"
                }}
                variant="subtitle2">
                    Good {timeOfDayDisplay()}{(currentUser) ? `, ${currentUser.username}` : null}
                </Typography>
                <Grid 
                container spacing={2}>
                    <Grid item xs={6}
                    style={{
                        minHeight: "300px"
                    }}
                    >
                        <Paper>
                            <Typography variant="h6"
                                style={{
                                    padding: "15px 15px 5px 15px"
                                }}
                            >
                                Monthly Bookings
                            </Typography>
                    <ResponsiveContainer width="100%" height={400}>
                    <LineChart
		// width={600}
		// height={400}
		data={data02}
		margin={{
			top: 5, right: 30, left: 20, bottom: 5,
		}}
	>
		<CartesianGrid strokeDasharray="3 3" />
		<XAxis dataKey="name" />
		<YAxis />
		<Tooltip />
		<Legend />
		<Line type="monotone" dataKey="Stevan" stroke="#263238" strokeDasharray="5 5" />
		<Line type="monotone" dataKey="Ali" stroke="#b71c1c" strokeDasharray="5 5" />
        <Line type="monotone" dataKey="Evan" stroke="#2196f3" strokeDasharray="5 5" />
        <Line type="monotone" dataKey="Rany" stroke="#00c853" strokeDasharray="5 5" />
        <Line type="monotone" dataKey="Dani" stroke="#01579b" strokeDasharray="5 5" />
        <Line type="monotone" dataKey="Kanny" stroke="#311b92" strokeDasharray="5 5" />
	</LineChart>
                    {/* <PieChart width={400} height={400}>
				<Pie dataKey="value" isAnimationActive={false} data={data02} cx={200} cy={200} outerRadius={80} fill="#3F51A6" label />
				<Tooltip />
			</PieChart> */}</ResponsiveContainer>
            </Paper>
                    </Grid>
                    <Grid item xs={6}
                     style={{
                        minHeight: "400px"
                    }}
                    >
                     
                <Paper>
                <Typography variant="h6"
                                style={{
                                    padding: "15px 15px 5px 15px"
                                }}
                            >
                                Avg. Per Person Rate
                            </Typography>
     <ResponsiveContainer width="100%" height={400}>
     <ComposedChart
       
        data={monthlyChartData}
        margin={{
          top: 20, right: 20, bottom: 20, left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Area type="monotone" dataKey="Monthly_Average" fill="#3f51b5" stroke="#3f51b5" fillOpacity={0.1} />
        
        <Line type="monotone" dataKey="Stevan" stroke="#263238" />
        <Line type="monotone" dataKey="Ali" stroke="#b71c1c" />
        <Line type="monotone" dataKey="Rany" stroke="#00c853" />
        <Line type="monotone" dataKey="Evan" stroke="#2196f3" />
        <Line type="monotone" dataKey="Dani" stroke="#01579b" />
        <Line type="monotone" dataKey="Kanny" stroke="#311b92" />
      </ComposedChart>




{/* 
                    <BarChart
		// width={600}
		// height={400}
		data={monthlyChartData}
		margin={{
			top: 5, right: 30, left: 20, bottom: 5,
		}}
	>
		<CartesianGrid strokeDasharray="3 3" />
		<XAxis dataKey="name" />
		<YAxis />
		<Tooltip />
		<Legend />
        <Bar dataKey="Monthly_Average"  fill="#3f4a59" />
		<Bar dataKey="Stevan" stackId="a" fill="#263238" />
		<Bar dataKey="Ali" stackId="a" fill="#b71c1c" />
        <Bar dataKey="Rany" stackId="a" fill="#00c853" />
		<Bar dataKey="Evan" stackId="a" fill="#2196f3" />
        <Bar dataKey="Dani" stackId="a" fill="#01579b" />
		<Bar dataKey="Kanny" stackId="a" fill="#311b92" />
	</BarChart> */}

    </ResponsiveContainer></Paper>
    
                    </Grid>

                    <Grid item xs={6}
                    style={{
                        minHeight: "300px"
                    }}
                    >
                        <Paper>
                            <Typography variant="h6"
                                style={{
                                    padding: "15px 15px 5px 15px"
                                }}
                            >
                                Top Booking Sources
                            </Typography>
                    <ResponsiveContainer width="100%" height={400}>
                    <LineChart
		// width={600}
		// height={400}
		data={TopBookingSourcesData}
		margin={{
			top: 5, right: 30, left: 20, bottom: 5,
		}}
	>
		<CartesianGrid strokeDasharray="3 3" />
		<XAxis dataKey="name" />
		<YAxis />
		<Tooltip />
		<Legend />
		<Line type="monotone" dataKey="Called" stroke="#263238" strokeDasharray="5 5" />
		<Line type="monotone" dataKey="Emailed" stroke="#b71c1c" strokeDasharray="5 5" />
        <Line type="monotone" dataKey="Been_Here_Before" stroke="#2196f3" strokeDasharray="5 5" />
        <Line type="monotone" dataKey="EventSource" stroke="#00c853" strokeDasharray="5 5" />
        <Line type="monotone" dataKey="WeddingWire" stroke="#01579b" strokeDasharray="5 5" />
        <Line type="monotone" dataKey="Facebook" stroke="#311b92" strokeDasharray="5 5" />
        <Line type="monotone" dataKey="Instagram" stroke="#f57f17" strokeDasharray="5 5" />
        <Line type="monotone" dataKey="Google" stroke="#33691e" strokeDasharray="5 5" />
        <Line type="monotone" dataKey="Referral" stroke="#1a237e" strokeDasharray="5 5" />


	</LineChart>
                    {/* <PieChart width={400} height={400}>
				<Pie dataKey="value" isAnimationActive={false} data={data02} cx={200} cy={200} outerRadius={80} fill="#3F51A6" label />
				<Tooltip />
			</PieChart> */}</ResponsiveContainer>
            </Paper>
                    </Grid>



                    <Grid item xs={6}>
                        Some other random chart...
                    </Grid>
                </Grid>
                
            </Grid>
        </Grid>
    )
}
