import React, { useEffect, useState } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter
} from "react-table";
import {Grid, DialogTitle, Dialog, DialogContent, Typography   } from '@material-ui/core/';
import AuthService from "../services/auth.service";
import authHeader from '../services/auth-header';
import Skeleton from '@material-ui/lab/Skeleton';



import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Sidebar from '../Sidebar';

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faPlus,
//   faChevronUp,
//   faChevronDown
// } from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter
}) {
  const count = preGlobalFilteredRows.length;

  return (
    <span className="lookup mb-1">
      {" "}
      <input
        className="w-200px"
        value={globalFilter || ""}
        onChange={e => {
          setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`${count} quotes...`}
      />
    </span>
  );
}

function Table({data, handleQuotesDialog}) {
  let history = useHistory();



  const columns = React.useMemo(
    () => [
      {
        Header: 'Client Name',
        accessor: 'client_name', 
      },
      {
        Header: 'Quoted Date',
        accessor: 'event_date', style: {textAlign: "center"},
        sortType: (a, b) => {
          var a1 = new Date(a).getTime();
          var b1 = new Date(b).getTime();
        if(a1<b1)
        return 1;
        else if(a1>b1)
        return -1;
        else
        return 0;
        }
      },
      // {
      //   Header: 'Client Number',
      //   accessor: 'client_number',
      // },
      {
        Header: 'Client Email',
        accessor: 'client_email', 
      },
      {
        Header: 'Client Phone',
        accessor: 'client_phone', 
      }
    ],
    []
  )
  
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    pageOptions,
    page,
    state,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const pageCount = 1;

  
  // Render the UI for your table
  return (
    <>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <table
        className="table table-separated bookings_table"
        style={{
          width: "100%"
        }}
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}
              
            >
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}
                style={{
                  textAlign: "left",
                  cursor: "pointer"
                }}
                >
                  {column.render("Header")} &nbsp;
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                       <ExpandLessIcon />
                      ) : (
                       <ExpandMoreIcon />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {(data.length < 1) ? <><Skeleton height={50} /><Skeleton height={50} /><Skeleton height={50} /><Skeleton height={50} /><Skeleton height={50} /><Skeleton height={50} /><Skeleton height={50} /><Skeleton height={50} /><Skeleton height={50} /><Skeleton height={50} /><Skeleton height={50} /><Skeleton height={50} /><Skeleton height={50} /><Skeleton height={50} /></> : null}

        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                onClick={() =>
                    
                    handleQuotesDialog(row.original.quote_id)
                }
                {...row.getRowProps()}
                id={row.original.quote_id}
                className="booking_row"
                style={{
                  fontWeight: "400",
                  height: "50px",
                  fontSize: "16px"
                }}
              >
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}
                      style={{padding: "5px"}}
                    >{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
       
      </div>
    </>
  );
}

function QuotesList() {
    useEffect(() => {
        document.title = "TG Quotes"
      }, [])
  const [content, setContent] = useState("");
  let history = useHistory();


 
  //const currentUser = AuthService.getCurrentUser();

  const [viewQuotesDialog, setViewQuotesDialog] = useState({
    isOpen: false,
    isLoadingQuote: true,
    quoteData: {
        quote_quote_details: null
    }
})

const handleQuotesDialog = (quote_id) => {
    setViewQuotesDialog({...viewQuotesDialog, isOpen: true, isLoadingQuote: true})

     axios.post('https://ps.torontograndcentre.com/tgc_back/bookingsClass.php', { // Live
  //  axios.post('http://localhost/tgc_back/bookingsClass.php', { // Local
      method: "get_quote_details",
      requested_quote_id: quote_id,
    }, { headers: authHeader() })
    .then(function (response) {
      //console.log(response)
      if(response.status === 200 && response.data.status === "Quote retrieved") {
        setViewQuotesDialog({...viewQuotesDialog, isOpen: true, isLoadingQuote: false, quoteData: response.data.quote_data})
   
      } else {
        console.log("Error retrieving content")
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        history.replace("/login")
       }
    });
     
  }


  const [dateQuotes, setQuotes] = useState([]);

    useEffect(() => {
     axios.post('https://ps.torontograndcentre.com/tgc_back/bookingsClass.php', { // Live
  //  axios.post('http://localhost/tgc_back/bookingsClass.php', { // Local
      method: "get_quotes_list",
    }, { headers: authHeader() })
    .then(function (response) {
    //  console.log(response)
      if(response.status === 200 && response.data.status === "Quotes retrieved") {
        setQuotes(response.data.quotes_list_data)
   
      } else {
        console.log("Error error retrieving content")
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        history.replace("/login")
       }
    });
  
}, [])

  return (
    <Grid container>
      <Grid item xs={2}
          style={{maxWidth: "260px"}}>
            <p>
             
      </p>
      <Sidebar />
      </Grid>
      <Grid item xs={10}
      style={{
        paddingLeft: "25px"
    }}
    >
      <Typography
                style={{
                    padding: "15px 0px"
                }}
                variant="h6">
                    Bookings
                </Typography>
      <Table  
      handleQuotesDialog={handleQuotesDialog}
      data={dateQuotes}
      
      />
      </Grid>

      
                                <Dialog 
    onClose={
      () => {
        setViewQuotesDialog({...viewQuotesDialog,
          isOpen: false})
      }
    } aria-labelledby="customized-dialog-title" 
    open={viewQuotesDialog.isOpen}
    maxWidth="sm"
    
    >
        <DialogTitle id="customized-dialog-title" onClose={
          () => {
            console.log("Close")
          }
        }>
          Quote Details
        </DialogTitle>
        <DialogContent dividers
        style={{
          width: "400px"
        }}
        >
         <div
                                        style={{
                                            padding: "15px 5px",
                                            minWidth: "350px"
                                        }}
                                    >
                                <Typography
                                variant="body2"
                                component="div"
                                >
                                    {/* {viewQuotesDialog.isLoadingQuote ? <Skeleton animation="wave" /> : `Quote: ${viewQuotesDialog.quoteData.quote_quote_details}`} */}
                                    {viewQuotesDialog.isLoadingQuote ? <Skeleton animation="wave" /> : <><Typography
                                variant="subtitle2"
                                >Quote</Typography> {viewQuotesDialog.quoteData.quote_quote_details}</>}
                                </Typography>
                                </div>

                                <div
                                        style={{
                                            padding: "5px",
                                            minWidth: "350px"
                                        }}
                                    >
                                <Typography
                                variant="body2"
                                component="div"
                                >
                                    {/* {viewQuotesDialog.isLoadingQuote ? <Skeleton animation="wave" /> : `Quoted On: ${viewQuotesDialog.quoteData.quote_date_quoted}`} */}
                                    {viewQuotesDialog.isLoadingQuote ? <Skeleton animation="wave" /> : <><Typography
                                variant="subtitle2"
                                >Quoted On</Typography> {viewQuotesDialog.quoteData.quote_date_quoted}</>}
                                </Typography></div>

                                <div
                                        style={{
                                            padding: "5px",
                                            minWidth: "350px"
                                        }}
                                    >
                                <Typography
                                variant="body2"
                                component="div"
                                >
                                   {viewQuotesDialog.isLoadingQuote ? <Skeleton animation="wave" /> : <><Typography
                                variant="subtitle2"
                                >Interested In</Typography> {viewQuotesDialog.quoteData.quote_date_requested}</>}
                                    
                                </Typography></div>

                                <div
                                        style={{
                                            padding: "5px",
                                            minWidth: "350px"
                                        }}
                                    >
                                <Typography
                                variant="body2"
                                component="div"
                                >
                                    {viewQuotesDialog.isLoadingQuote ? <Skeleton animation="wave" /> : <><Typography
                                variant="subtitle2"
                                >Quoted By</Typography> {viewQuotesDialog.quoteData.event_quoted_by}</>}
                                  
                                </Typography></div>
  
        </DialogContent>
        {/* <DialogActions>
        <Button autoFocus 
           onClick={() => {
            setQuotesDialog({...quotesDialog,
              isOpen: false})
          }}
          variant="outlined"
          disableElevation
          color="primary">
            Cancel
          </Button>
        </DialogActions> */}
       
      </Dialog>
    </Grid>
   
  
    // <div className="card">
    //   <h4 className="card-title">
    //     <strong>Bookings</strong>
    //     <div className="float-right">
    //       <Link
    //         className="btn btn-float btn-sm btn-dark"
    //         to="/booking/new"
    //         style={{ color: "#ffffff" }}
    //       >
    //       </Link>
    //     </div>
    //   </h4>

    //   <div className="card-body">
    //     <Table columns={columns} data={data} />
    //   </div>
    // </div>
  );
}

export default QuotesList;
