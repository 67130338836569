import React, {useState, useEffect} from 'react'
import { useForm, Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles(theme => ({
    textarea: {
      resize: "vertical"
    }
  }));

export default function ControlledTextField({formController, FieldValue, errorHandler, formRef, inputName, multiline, placeholder, 
  inputType, inputAdornment, inputEndAdornment, textFieldOnChange, autoFocus, timedOutTextFieldOnChange}) {
    const classes = useStyles();

    const [textFieldValue, setTextFieldValue] = useState();

    // useEffect(() => {
    //   if(timedOutTextFieldOnChange) {
    // //  if(timedOut) {
    //     const timeout = setTimeout(() => {
         
    //         timedOutTextFieldOnChange(textFieldValue)
          
         
    //      // console.log(textFieldValue)
    //       ///store.dispatch({ type: "CHANGE_INPUT", val: value });
    //     }, 500)
    
    //     // if this effect run again, because `value` changed, we remove the previous timeout
    //     return () => clearTimeout(timeout)
    //  // }
    //   }
    // }, [textFieldValue])

    useEffect(() => {
        if(typeof FieldValue !== "undefined") {
            setTextFieldValue(FieldValue)
        } else {
            setTextFieldValue('')
        }
      
      }, [FieldValue])

      function handleTextFieldChange(data) {
      
        setTextFieldValue(data)
        if(textFieldOnChange) {
          textFieldOnChange(data, inputName)
        }
      }


    return (
        <TextField
        style={{backgroundColor: "#fff"}}
        autoFocus={autoFocus}
        id={inputName}
        name={inputName}
        variant="outlined"
        inputRef={formRef}
        //ref={formController}
        error={errorHandler}
        fullWidth
        multiline={multiline ? true : false}
        rows={multiline ? 4 : null}
        size="small"
        //defaultValue={defaultInputValue}
        value={textFieldValue}
        key={inputName}
        placeholder={placeholder}
        type={inputType ? inputType : null}
        onChange={(e) => {
          handleTextFieldChange(e.currentTarget.value)
        }}
        InputProps={{ 
          // className: classes.textarea,
          startAdornment: inputAdornment,
          endAdornment: inputEndAdornment
         }}
      />
    //     <Controller
    //     name="appointmentNotifyViaEmail"
    //     id="appointmentNotifyViaEmail"
    //     control={formController}
    //     defaultValue={false}
    //     //rules={{ required: true }}
    //     render={props =>
    //       <FormControlLabel
    //       control={<Checkbox  
    //        // onChange={e => props.onChange(!e.target.checked)}
    //        onChange={(e) => {
    //          setChecked(!isChecked)
    //        }}
    //       checked={isChecked}
    //       color="primary" size="small" />}
    //       label="Via email"
    //     />
          
    //     } // props contains: onChange, onBlur and value
    //   />
    )
}
