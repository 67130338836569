import React, { useEffect, useState } from "react";

import { Link, NavLink, useHistory } from "react-router-dom";
import {Grid, Button, TextField, Select, FormControl, Menu ,MenuItem , InputLabel, Checkbox, FormGroup, FormControlLabel,FormLabel,
  Typography, Divider, Table, TableContainer, TableRow, TableCell, TableHead,TableBody, InputAdornment, Card, CardActions, CardContent, Box, Switch,Snackbar, SnackbarContent   } from '@material-ui/core/';
  import AuthService from "./services/auth.service";

  import UserService from "./services/user.service";

  import DashboardIcon from '@material-ui/icons/Home';
  import CalendarIcon from '@material-ui/icons/Today';
  import EventsIcon from '@material-ui/icons/EventNote';
  import MenusIcon from '@material-ui/icons/RestaurantMenu';
  import AddIcon from '@material-ui/icons/AddCircleOutline';
  import QuotesIcon from '@material-ui/icons/AttachMoney';

function Sidebar(props) {
  let history = useHistory();
    


  const currentUser = JSON.parse(sessionStorage.getItem('token'));

 // console.log(JSON.parse(sessionStorage.getItem('token')))
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="sidebar">
     <div className="sidebar-header">TG</div>
      <nav className="sidebar-navigation">
    <Grid 
    >
      <nav className="sidebar-navigation">
      <NavLink className="menu-link" to="/">
      <Button
      style={{
        color: "#fff",
        justifyContent: "left",
        paddingLeft: "25px"
      }}
        color="secondary"
        disableElevation
        fullWidth
        startIcon={<DashboardIcon />}
        disableRipple
      >
        Dashboard
      </Button>
      </NavLink>
      <NavLink className="menu-link" to="/calendar">
      <Button
      style={{
        color: "#fff",
        justifyContent: "left",
        paddingLeft: "25px"
      }}
        color="secondary"
        disableElevation
        fullWidth
        startIcon={<CalendarIcon />}
        disableRipple
      >
        Calendar
      </Button>
      </NavLink>

      <NavLink className="menu-link" to="/bookings">
      <Button
        color="secondary"
        style={{
          color: "#fff",
          justifyContent: "left",
          paddingLeft: "25px"
        }}
        disableElevation
        fullWidth
        startIcon={<EventsIcon />}
        disableRipple
      >
        Bookings
      </Button>
      </NavLink>

      <NavLink className="menu-link" to="/quotes">
      <Button
        color="secondary"
        style={{
          color: "#fff",
          justifyContent: "left",
          paddingLeft: "25px"
        }}
        disableElevation
        fullWidth
        startIcon={<QuotesIcon />}
        disableRipple
      >
        Quotes
      </Button>
      </NavLink>

      <Button
       aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}
       className="menu-link"
        color="secondary"
        style={{
          color: "#fff",
          justifyContent: "left",
          paddingLeft: "17px"
        }}
        disableElevation
        fullWidth
        startIcon={<MenusIcon />}
        disableRipple
      >
        Menus
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem 
          onClick={() => {
            window.open('https://www.torontogrand.ca/wp-content/uploads/2021/06/2021-Grand-Package.pdf', '_blank').focus();
          }}
        >Grand Package</MenuItem>
        <MenuItem 
          onClick={() => {
            window.open('https://www.torontogrand.ca/wp-content/uploads/2021/11/2021-Pakistani-Packages.pdf', '_blank').focus();
          }}
        >Pakistani Package</MenuItem>
        <MenuItem 
          onClick={() => {
            window.open('https://www.torontogrand.ca/wp-content/uploads/2021/11/2021-East-Indian-Packages.pdf', '_blank').focus();
          }}
        >Indian Menu</MenuItem>
        <MenuItem 
          onClick={() => {
            window.open('https://www.torontogrand.ca/wp-content/uploads/2021/06/West-Indian-Package.pdf', '_blank').focus();
          }}
        >West Indian</MenuItem>
        <MenuItem 
          onClick={() => {
            window.open('https://www.torontogrand.ca/wp-content/uploads/2021/11/Package-Inclusions.pdf', '_blank').focus();
          }}
        >Included In Package</MenuItem>
        
      </Menu>


      <NavLink className="menu-link" to="/bookings/view/add">
      <Button
        variant="contained"
        color="primary"
        disableElevation
        fullWidth
        startIcon={<AddIcon />}
      >
        Add New Booking
      </Button>
      </NavLink>
      <Typography variant="caption"
      component="div"
        style={{
          color: "rgb(208 208 208)",
          textAlign: 'center',
          marginTop: "20px"
        }}
      >{(currentUser) ? `Logged in as: ${currentUser.username}` : null}</Typography>
      </nav>
     
    </Grid>
    </nav>
    </div>
    // <div className="sidebar">
    //   <div className="sidebar-header">TG</div>
    //   <nav className="sidebar-navigation">
    //     <ul className="menu">
    //       <li className="menu-item">
    //         <NavLink className="menu-link" to="/">
    //           <span className="icon ti-layout"></span>
    //           <span className="title">Dashboard</span>
    //         </NavLink>
    //       </li>

    //       <li className="menu-item">
    //         <NavLink className="menu-link" to="/bookings">
    //           <span className="icon ti-layout"></span>
    //           <span className="title">Bookings</span>
    //         </NavLink>
    //       </li>
    //       <li className="menu-item">
    //         <NavLink className="menu-link" to="/clients">
    //           <span className="icon ti-layout"></span>
    //           <span className="title">Clients</span>
    //         </NavLink>
    //       </li>
    //       <li className="menu-item">
    //         <NavLink className="menu-link" to="/appointments">
    //           <span className="icon ti-layout"></span>
    //           <span className="title">Appointments</span>
    //         </NavLink>
    //       </li>
    //       <li className="menu-item">
    //         <NavLink className="menu-link" to="/tools">
    //           <span className="icon ti-layout"></span>
    //           <span className="title">Tools</span>
    //         </NavLink>
    //         <ul className="menu-submenu">
    //           <li className="menu-item">
    //             <a
    //               className="menu-link"
    //               href="../samples/invoicer/index.html"
    //               target="_blank"
    //             >
    //               <span className="dot"></span>
    //               <span className="title">Invoicer</span>
    //             </a>
    //           </li>

    //           <li className="menu-item">
    //             <a
    //               className="menu-link"
    //               href="../samples/job/index.html"
    //               target="_blank"
    //             >
    //               <span className="dot"></span>
    //               <span className="title">Job Management</span>
    //             </a>
    //           </li>

    //           <li className="menu-item">
    //             <a
    //               className="menu-link"
    //               href="../samples/support/index.html"
    //               target="_blank"
    //             >
    //               <span className="dot"></span>
    //               <span className="title">Support System</span>
    //             </a>
    //           </li>
    //         </ul>
    //       </li>
    //     </ul>
    //   </nav>
    //   Sidebar test with {props.test}{" "}
    // </div>
  );
}

export default Sidebar;
