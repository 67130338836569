import React, { useEffect, useState } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter
} from "react-table";
import {Grid, Typography} from '@material-ui/core';
import AuthService from "../services/auth.service";
import authHeader from '../services/auth-header';

import Skeleton from '@material-ui/lab/Skeleton';


import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Sidebar from '../Sidebar';

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faPlus,
//   faChevronUp,
//   faChevronDown
// } from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter
}) {
  const count = preGlobalFilteredRows.length;

  return (
    <span className="lookup mb-1">
      {" "}
      <input
        className="w-200px"
        value={globalFilter || ""}
        onChange={e => {
          setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`${count} events...`}
      />
    </span>
  );
}

function Table({data}) {
  let history = useHistory();



  const columns = React.useMemo(
    () => [
      {
        Header: 'Client Name',
        accessor: 'client_name', 
      },
      {
        Header: 'Event Date',
        accessor: 'event_date', style: {textAlign: "center"},
        sortType: (a, b) => {
          var a1 = new Date(a).getTime();
          var b1 = new Date(b).getTime();
        if(a1<b1)
        return 1;
        else if(a1>b1)
        return -1;
        else
        return 0;
        }
      },
      {
        Header: 'Booking Date',
        accessor: 'booking_date', style: {textAlign: "center"},
        // sortType: (a, b) => {
        //   var a1 = new Date(a).getTime();
        //   var b1 = new Date(b).getTime();
        // if(a1<b1)
        // return 1;
        // else if(a1>b1)
        // return -1;
        // else
        // return 0;
        // }
      },
      // {
      //   Header: 'Client Number',
      //   accessor: 'client_number',
      // },
      {
        Header: 'Event Halls',
        accessor: 'event_halls', 
      },
      {
        Header: 'Event Guests',
        accessor: 'event_guests', 
      }
    ],
    []
  )
  
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    pageOptions,
    page,
    state,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const pageCount = 1;
  // Render the UI for your table
  return (
    <>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      
      <table
        className="table table-separated bookings_table"
        style={{
          width: "100%"
        }}
        {...getTableProps()}
      >
        
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}
              
            >
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}
                style={{
                  textAlign: "left",
                  cursor: "pointer"
                }}
                >
                  {column.render("Header")} &nbsp;
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                       <ExpandLessIcon />
                      ) : (
                       <ExpandMoreIcon />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {(data.length < 1) ? <><Skeleton height={50} /><Skeleton height={50} /><Skeleton height={50} /><Skeleton height={50} /><Skeleton height={50} /><Skeleton height={50} /><Skeleton height={50} /><Skeleton height={50} /><Skeleton height={50} /><Skeleton height={50} /><Skeleton height={50} /><Skeleton height={50} /><Skeleton height={50} /><Skeleton height={50} /></> : null}
        <tbody {...getTableBodyProps()}>
       
          {rows.map((row, i) => {
           // console.log(row.original.booking_id)
            prepareRow(row);
            return (
              <tr
                onClick={() =>
                  history.push("/bookings/view/" + row.original.booking_id)
                }
                {...row.getRowProps()}
                id={row.original.booking_id}
                className="booking_row"
                style={{
                  fontWeight: "400",
                  height: "50px",
                  fontSize: "16px"
                }}
              >

               
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}
                      style={{padding: "5px"}}
                    >{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        {/*<li className="page-item">
          <span
            className="page-link"
            aria-label="Previous"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Previous</span>
          </span>
        </li>*/}
        {/* <li className="page-item">
          <span
            className="page-link"
            aria-label="Previous"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Previous</span>
          </span>
        </li>
        <li className="page-item">
          <span
            className="page-link"
            aria-label="Next"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <span aria-hidden="true">&raquo;</span>
            <span className="sr-only">Next</span>
          </span>
        </li> */}
        {/*<button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
      </button>{" "}
        <span className="">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span> | Go to page: </span>*/}
        {/* <div className="col-md-2">
          <div className="input-group">
            <input
              className="form-control"
              type="number"
              defaultValue={pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "100px" }}
            />

            <select
              className="form-control"
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div> */}
      </div>
    </>
  );
}

function Bookings() {
  useEffect(() => {
    document.title = "TG Bookings"
  }, [])
  const [content, setContent] = useState("");
  let history = useHistory();


  //const currentUser = AuthService.getCurrentUser();



  const [bookingsData, setBookings] = useState([]);

    useEffect(() => {
     axios.post('https://ps.torontograndcentre.com/tgc_back/bookingsClass.php', { // Live
  //  axios.post('http://localhost/tgc_back/bookingsClass.php', { // Local
      method: "get_events_list",
    }, { headers: authHeader() })
    .then(function (response) {
     // console.log(response)
      if(response.status === 200 && response.data.status === "Events retrieved") {
        setBookings(response.data.events_list_data)
        // setEventPayments(response.data.event_payment_data)
        // if(response.data.event_payment_data.length > 0) {
        //   let tmpPaymentsArray = 0;

        //   // iterate through the payment numbers
        //   response.data.event_payment_data.map((paymentAmount) => {
        //       tmpPaymentsArray += parseFloat(paymentAmount.payment_amount)
        //   })
        // }
      } else {
        console.log("Error error retrieving content")
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        history.replace("/login")
       }
    });
  
}, [])

  // useEffect(() => {
  //   async function getBookings() {
  //     const bookingsData = await axios("http://localhost:3001/bookings/");
  //     setBookings(bookingsData.data);
  //   }
  //   getBookings();
  // }, []);

  // const columns = React.useMemo(
  //   () => [
  //     {
  //       Header: " ",
  //       columns: [
  //         {
  //           Header: "Booking ID",
  //           accessor: "booking_number"
  //         },
  //         {
  //           Header: "Name",
  //           accessor: "customer_name"
  //         },
  //         {
  //           Header: "Phone",
  //           accessor: "customer_phone"
  //         },
  //         {
  //           Header: "Email",
  //           accessor: "customer_email"
  //         },
  //         {
  //           Header: "Date",
  //           accessor: "event_date"
  //         }
  //       ]
  //     }
  //   ],
  //   []
  // );

  // const objData = [];

  // bookingsData1.map(booking =>
  //   objData.push({
  //     booking_number: booking.booking_number,
  //     customer_name: booking.customer_name,
  //     customer_phone: booking.customer_phone,
  //     customer_email: booking.customer_email,
  //     event_date: booking.event_date
  //   })
  // );

  // const data = React.useMemo(
  //   () => bookingsData,
  //   //[
  //    // bookingsData
  //     // {
  //     //   client_name: 'Hello',
  //     //   event_date: 'World',
  //     //   event_halls: 'email@email.com',
  //     // //  client_number: '416-545-1010',
  //     //   event_guests: '220'
  //     // },
  //     // {
  //     //   client_name: 'react-table',
  //     //   event_date: 'rocks',
  //     //   event_halls: 'email@email.com',
  //     // //  client_number: '416-545-1010',
  //     //   event_guests: '170'
  //     // },
  //     // {
  //     //   client_name: 'whatever',
  //     //   event_date: 'you want',
  //     //   event_halls: 'email@email.com',
  //     // //  client_number: '416-545-1010',
  //     //   event_guests: '250'
  //     // },
  //  // ],
  //   []
  // )
  //console.log(objData);
  return (
    <Grid container>
      <Grid item xs={2}
          style={{maxWidth: "260px"}}>
            <p>
             
        {/* <strong>Token:</strong> {currentUser.accessToken.substring(0, 20)} ...{" "}
        {currentUser.accessToken.substr(currentUser.accessToken.length - 20)} */}
      </p>
      <Sidebar />
      </Grid>
      <Grid item xs={10}
      style={{
        paddingLeft: "25px"
    }}
    >
      <Typography
                style={{
                    padding: "15px 0px"
                }}
                variant="h6">
                    Bookings
                </Typography>
      
      <Table  
      
      data={bookingsData}
      
      />
      </Grid>
    </Grid>
   
  
    // <div className="card">
    //   <h4 className="card-title">
    //     <strong>Bookings</strong>
    //     <div className="float-right">
    //       <Link
    //         className="btn btn-float btn-sm btn-dark"
    //         to="/booking/new"
    //         style={{ color: "#ffffff" }}
    //       >
    //       </Link>
    //     </div>
    //   </h4>

    //   <div className="card-body">
    //     <Table columns={columns} data={data} />
    //   </div>
    // </div>
  );
}

export default Bookings;
