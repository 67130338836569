import React, {useState, useRef, useEffect} from 'react';
import {Grid, Button, TextField, Select, FormControl,MenuItem , InputLabel, Checkbox, FormGroup, FormControlLabel,FormLabel,
    Typography, Divider, Table, TableContainer, TableRow, TableCell, TableHead,TableBody, InputAdornment, Card, CardActions, CardContent, Box, Switch,Snackbar, SnackbarContent,
    Drawer,DialogTitle, DialogContent,DialogActions,Accordion ,AccordionDetails , AccordionSummary, Dialog   } from '@material-ui/core/';
import Sidebar from '../Sidebar';
import authHeader from '../services/auth-header';
import Skeleton from '@material-ui/lab/Skeleton';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AuthService from "../services/auth.service";

import MomentUtils from '@date-io/moment';
import moment from 'moment';

import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";

import axios from "axios";
import { Link, useHistory } from "react-router-dom";


const schema = yup.object().shape({
    client_name: yup.string().required(),
    quote_details: yup.string().required(),
    requested_quote_date: yup.string().required(),
  });


export default function Calendar() {
    useEffect(() => {
        document.title = "TG Calendar"
      }, [])

      const currentUser = JSON.parse(sessionStorage.getItem('token'));


    const { control, handleSubmit, formState:{ errors }, setValue, reset } = useForm();
    
    const calendarRef = useRef(null)
    const [appointmentsEvents, setAppointmentsEvents] = useState([]);
    const [quotesDrawer, setQuotesDrawer] = useState({
        isOpen: false,
        quotesDate: null,
        isLoadingQuotes: false,
        quotesData: []
    })

    let history = useHistory();

    const [quotesData, setQuotesData] = useState([])
    const [quotesDialog, setQuotesDialog] = useState({
        isOpen: false
    })

    const [popperState, setPopperState] = useState({
        isOpen: false,
        popperColor: "#40b03c",
        popperMessage: null
      });
      
      const handlePopoverClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setPopperState({...popperState, isOpen: false})
       // setPopperState(false);
      };

    useEffect(() => {
        //const object = localStorage.getItem('object');
        reset()
        setValue("requested_quote_date", quotesDrawer.quotesDate);
      }, [quotesDrawer, quotesDialog])

    const handleCalendarDateClick = (e) => {
       
       setQuotesDrawer({...quotesDrawer,
            isOpen: true,
            quotesDate: e.dateStr,
            isLoadingQuotes: true
            })

            axios.post('https://ps.torontograndcentre.com/tgc_back/bookingsClass.php', { // Live
          // axios.post('http://localhost/tgc_back/bookingsClass.php', { // Local
                method: "get_date_quotes",
                quote_date: e.dateStr
                }, { headers: authHeader() })
                .then(function (response) {
               // console.log(response)
                if(response.status === 200 && response.data.status === "Quotes retrieved") {
                    setQuotesDrawer({...quotesDrawer,
                        isOpen: true,
                        isLoadingQuotes: false,
                        quotesDate: e.dateStr,
                        quotesData: response.data.quotes_list_data
                        })
                    //setAppointmentsEvents(response.data.quotes_list_data)
                    //setBookings(response.data.events_list_data)
                    // setEventPayments(response.data.event_payment_data)
                    // if(response.data.event_payment_data.length > 0) {
                    //   let tmpPaymentsArray = 0;

                    //   // iterate through the payment numbers
                    //   response.data.event_payment_data.map((paymentAmount) => {
                    //       tmpPaymentsArray += parseFloat(paymentAmount.payment_amount)
                    //   })
                    // }
                } else {
                    console.log("Error retrieving quotes")
                }
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        history.replace("/login")
                       }
               // console.log(error);
            });
        // setLoadingOverlayState(!loadingOverlayState)
       
        // setAppointmentIsAllDay(false);
    
        // const currentSelectedDateTime = new Date(zonedTimeToUtc(e.dateStr, 'America/New_York'));
        // const selectedStartHour = currentSelectedDateTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
        // currentSelectedDateTime.setHours(currentSelectedDateTime.getHours() + 1);
    
        // const selectedEndHour = currentSelectedDateTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
        
        //   setDrwaerOpen({
        //     ...drawerIsOpen,
        //     openDrwaer: true,
        //     selectedDate: e.dateStr,
        //     selectedStartDate: e.dateStr,
        //     selectedEndDate: currentSelectedDateTime,
        //     passedStartHour: selectedStartHour,
        //     passedEndHour: selectedEndHour,
        //     isEditingAppoint: false
        //   })
    
        //  setEditingEventState('')
      }

      const handleEventClick = (e) => {
        history.replace("bookings/view/"+e.event.id)
        // setEventData({
        //   ...eventData,
        //   loadingState: true
        // })
        //console.log(e.event.id)
    //    console.log(e.event.id)
      
      }

      const handleCalendarEventsLoad = (e) => {

        let startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
        let endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
          if(calendarRef.current) {
            //const calendarAPI = calendarRef.current.getApi();

            startOfMonth = moment(calendarRef.current.getApi().getDate()).format("YYYY-MM-DD");
            endOfMonth = moment(calendarRef.current.getApi().getDate()).endOf('month').format("YYYY-MM-DD");
           // console.log(moment(calendarRef.current.getApi().getDate()).endOf('month').format("YYYY-MM-DD"))
           // console.log(moment(e.endStr).format("YYYY-MM-DD"))
          }

           axios.post('https://ps.torontograndcentre.com/tgc_back/bookingsClass.php', { // Live
       //   axios.post('http://localhost/tgc_back/bookingsClass.php', { // Local
                method: "get_events_list_by_date",
                start_date: startOfMonth,
                end_date: endOfMonth
                }, { headers: authHeader() })
                .then(function (response) {
             //   console.log(response)
                if(response.status === 200 && response.data.status === "Events retrieved") {
                    setAppointmentsEvents(response.data.events_list_data)
                    //setBookings(response.data.events_list_data)
                    // setEventPayments(response.data.event_payment_data)
                    // if(response.data.event_payment_data.length > 0) {
                    //   let tmpPaymentsArray = 0;

                    //   // iterate through the payment numbers
                    //   response.data.event_payment_data.map((paymentAmount) => {
                    //       tmpPaymentsArray += parseFloat(paymentAmount.payment_amount)
                    //   })
                    // }
                } else {
                    console.log("Error retrieving content")
                }
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        history.replace("/login")
                       }
            });
      }

      const onSubmitNewQuote = (data) => {
         axios.post('https://ps.torontograndcentre.com/tgc_back/bookingsClass.php', { // Live
       // axios.post('http://localhost/tgc_back/bookingsClass.php', { // Local
            method: "add_new_quote",
            quote_data: data,
            quoted_by: currentUser.username,
            }, { headers: authHeader() })
            .then(function (response) {
         //   console.log(response)
            if(response.status === 200 && response.data.status === "Quote added") {
               // setAppointmentsEvents(response.data.events_list_data)
               //console.log("Quote added")
              let tmpQuotesData = [...quotesDrawer.quotesData]
                
               tmpQuotesData.push({...data, quoted_by: currentUser.username, quotation_date: moment().format("YYYY-MM-DD")})

               setQuotesDrawer({...quotesDrawer,
               // isOpen: false,
                quotesData: tmpQuotesData
                })

                setQuotesDialog({
                    isOpen: false
                })

                setPopperState({isOpen: true, popperColor: "#40b03c", popperMessage: "Quote Saved"})
                //setBookings(response.data.events_list_data)
                // setEventPayments(response.data.event_payment_data)
                // if(response.data.event_payment_data.length > 0) {
                //   let tmpPaymentsArray = 0;

                //   // iterate through the payment numbers
                //   response.data.event_payment_data.map((paymentAmount) => {
                //       tmpPaymentsArray += parseFloat(paymentAmount.payment_amount)
                //   })
                // }
            } else {
                console.log("Error retrieving content")
            }
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    history.replace("/login")
                   }
        });
      }

      const [eventsLoading, setEventsLoading] = useState(false)

    return (
        <Grid
        container>
            <Grid item xs={2}
                style={{maxWidth: "260px"}}
            >
                <Sidebar />
            </Grid>
            <Grid item xs={10}
                style={{paddingLeft: "10px"}}
            >
                <Typography
                style={{
                    padding: "15px 0px 0px 0px"
                }}
                variant="h6">
                    Events Calendar
                </Typography>
                {/* {(eventsLoading) ? <Skeleton /> : null} */}
                    <FullCalendar
      ref={calendarRef}
        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
        
        initialView="dayGridMonth"
        headerToolbar={{
          right: "prev,next",
          center: "title",
         // right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
         left: ""
        }}
        buttonText={{
          list: "Weekly List View",
        }}
        dateClick={(e) => {
          handleCalendarDateClick(e)
        }
        }
        datesSet={(e) => {
            handleCalendarEventsLoad(e)
           // console.log(e)
        }}
        // loading={(isLoading, view) => {
        //     setEventsLoading(isLoading)
        //    // (isLoading) ? setEventsLoading(true) : setEventsLoading(false)
        // }}
        //droppable={true} // check if needed
        editable={true}
        events={
        //   { title: "event 1", date: "2020-11-01" }
          // { title: "event 2", date: "2020-10-07" },
         appointmentsEvents
         //appointmentsEvents
        }
        eventClick={(e) => {
          handleEventClick(e);
          
        }}
      />
                    
            </Grid>

            <Drawer anchor={"right"} 
           
            open={quotesDrawer.isOpen} 
           onClose={() => {setQuotesDrawer({...quotesDrawer,
            isOpen: false})}}
            >
                <DialogTitle id="scroll-dialog-title">
                    {/* {drawerIsOpen.isEditingAppoint ? "Edit appointment" : " Add new appointment"}
                    <IconButton aria-label="close"  onClick={() => {
                    setDrwaerOpen({ ...drawerIsOpen, openDrwaer: false });
                    // console.log("Close drawer");
                    }}
                    style={{position: "absolute", top: 8, right: 5}}
                    >
                    <CloseIcon />
                    </IconButton> */}
                    {quotesDrawer.quotesDate} Quotes
                </DialogTitle>
                <DialogContent dividers>
                <div
                 style={{
                    minWidth: "350px"
                }}
                >
                    {(quotesDrawer.isLoadingQuotes) ? 
                    <>
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          </>
                          : (quotesDrawer.quotesData.length > 0) ? 
                          quotesDrawer.quotesData.map((quoteData) => {
                            return   ( <Accordion 
                                //expanded={expanded === 'panel1'} 
                               // onChange={handleChange('panel1')}
                               style={{
                                   maxWidth: "350px"
                               }}
                                >
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                >
                                <Typography>{quoteData.client_name} <small>{quoteData.client_phone} {quoteData.client_email}</small></Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                style={{
                                    flexWrap: "wrap"
                                }}
                                >
                                    <div
                                        style={{
                                            padding: "15px 5px",
                                            minWidth: "350px"
                                        }}
                                    >
                                <Typography
                                variant="body2"
                                component="div"
                                >
                                    <strong>Quote:</strong> {quoteData.quote_details}
                                </Typography>
                                </div>

                                <div
                                        style={{
                                            padding: "5px",
                                            minWidth: "350px"
                                        }}
                                    >
                                <Typography
                                variant="body2"
                                component="div"
                                >
                                    <strong>Quotation Date:</strong> {quoteData.quotation_date}
                                </Typography></div>

                                <div
                                        style={{
                                            padding: "5px",
                                            minWidth: "350px"
                                        }}
                                    >
                                <Typography
                                variant="body2"
                                component="div"
                                >
                                    <strong>Interested In:</strong> {quoteData.requested_quote_date}
                                </Typography></div>

                                <div
                                        style={{
                                            padding: "5px",
                                            minWidth: "350px"
                                        }}
                                    >
                                <Typography
                                variant="body2"
                                component="div"
                                >
                                    <strong>Quoted By:</strong> {quoteData.quoted_by}
                                </Typography></div>
                                </AccordionDetails>
                            </Accordion>)
                          })
                          :
                          <Typography variant="caption">No quotes...</Typography>
                          }

                          {/* {(quotesDrawer.quotesData.length > 0) ? 
                          quotesDrawer.quotesData.map((quoteData) => {
                              console.log(quoteData)
                          })
                          :
                          <Typography variant="caption">No quotes...</Typography>
                          } */}

                </div>
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={() => {
                    setDrwaerOpen({ ...drawerIsOpen, openDrwaer: false });
                    // console.log("Close drawer");
                    }} color="primary">
                        Cancel
                    </Button> */}
                    <Button
                    disableElevation
                    disableRipple
                    variant="contained" type="submit" color="primary"
                    onClick={() => {
                        setQuotesDialog({...quotesDialog,
                        isOpen: true})
                    }}
                    >
                    Add New Quote
            
                    </Button>
        </DialogActions>
          </Drawer>


          <Dialog 
    onClose={
      () => {
        setQuotesDialog({...quotesDialog,
          isOpen: false})
      }
    } aria-labelledby="customized-dialog-title" 
    open={quotesDialog.isOpen}
    maxWidth="sm"
    
    >
        <form onSubmit={handleSubmit(onSubmitNewQuote)}>
        <DialogTitle id="customized-dialog-title" onClose={
          () => {
            console.log("Close")
          }
        }>
          Add Quote
        </DialogTitle>
        <DialogContent dividers
        style={{
          width: "400px"
        }}
        >
          <Grid container
          spacing="1"
          >
            <Grid item xs={6}>
            <Controller
                name="client_name"
                control={control}
                rules={{ required: true }}
               
                render={({ field }) => <TextField label="Name" variant="outlined" name="client_name" id="client_name" error={errors.client_name ? true : false} fullWidth {...field}  />}
            />

            </Grid>
            <Grid item xs={6}>
            <Controller
                name="requested_quote_date"
                control={control}
                rules={{ required: true }}
                render={({ field }) => <TextField label="Quoted Date" disabled variant="outlined" name="requested_quote_date" id="requested_quote_date" error={errors.requested_quote_date ? true : false} fullWidth {...field}  />}
            />
            </Grid>
            <Grid item xs={6}>
            <Controller
                name="client_phone"
                control={control}
                render={({ field }) => <TextField label="Phone" variant="outlined" name="client_phone" id="client_phone" fullWidth {...field}  />}
            />
            </Grid>
            <Grid item xs={6}>
            <Controller
                name="client_email"
                control={control}
                render={({ field }) => <TextField label="Email" variant="outlined" name="client_email" id="client_email" fullWidth {...field}  />}
            />
            </Grid>

            <Grid item xs={12}>
            <Controller
                name="quote_details"
                control={control}
                rules={{ required: true }}
                render={({ field }) => <TextField label="Quote Details" variant="outlined" name="quote_details" id="quote_details" multiline
                error={errors.quote_details ? true : false}
                rows={5} fullWidth {...field}  />}
            />
            </Grid>
          </Grid>
  
        </DialogContent>
        <DialogActions>
        <Button autoFocus 
           onClick={() => {
            setQuotesDialog({...quotesDialog,
              isOpen: false})
          }}
          variant="outlined"
          disableElevation
          color="primary">
            Cancel
          </Button>
          <Button autoFocus 
          onClick={() => {
           // handlePaymentAddition()
          }} 
          variant="contained"
          type="submit"
          disableElevation
          color="primary">
            Add Quote
          </Button>
        </DialogActions>
        </form>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={popperState.isOpen}
        autoHideDuration={2000}
        onClose={handlePopoverClose}
        >
        <SnackbarContent
          aria-describedby="message-id2"
         // className={"classes.snackbarStyleViaNestedContent"}
          style={{
            backgroundColor: popperState.popperColor,
    color: "#fff"
          }}
          message={
            <span id="message-id2">
              <div>{popperState.popperMessage}</div>
            </span>
          }
        />
          </Snackbar>
        </Grid>
        
    )
}
