import {React, useState, useEffect} from 'react'
import { Stage, Layer, Text, Star, Circle } from 'react-konva';
import {Grid, Button, TextField, Select, FormControl,MenuItem , InputLabel, Checkbox, FormGroup, FormControlLabel,FormLabel,
    Typography, Divider, Table, TableContainer, TableRow, TableCell, TableHead,TableBody, InputAdornment, Card, CardActions, CardContent, Box, Switch,Snackbar, SnackbarContent, IconButton   } from '@material-ui/core/';

import {fabric} from 'fabric'
//import hall_d from '../Dplan.jpg'; // with import
import hall_layout from '../hall_layout.jpg';
import _ from "lodash";

//     function generateShapes() {
//     return [...Array(10)].map((_, i) => ({
//       id: i.toString(),
//       x: Math.random() * window.innerWidth,
//       y: Math.random() * window.innerHeight,
//       rotation: Math.random() * 180,
//       isDragging: false,
//     }));
//   }

//   const INITIAL_STATE = generateShapes();

  const uid = function(){
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
}

export default function CanvasTool({passedHalls}) {

  //  console.log(passedHalls)
    const [canvas, setCanvas] = useState('');

    useEffect(() => {
        setCanvas(initCanvas());
       
      }, []);

      if(canvas) {
     //   console.log("Set BG")
        canvas.setBackgroundImage(hall_layout, canvas.renderAll.bind(canvas), {
            crossOrigin: 'anonymous',
            // Needed to position backgroundImage at 0/0
            originX: 'left',
            originY: 'top'
          });

          if(passedHalls) {
            //console.log(!_.includes(passedHalls, 'D'))
        
            if(!_.includes(passedHalls, 'A')) {
              var square = new fabric.Rect({ 
                width: 295, 
                height: 297, 
                left: 3, 
                top: 13, 
                fill: 'rgba(0,0,0,0.03)'
              });
              square.selectable = false
              canvas.add(square); 
            }

            if(!_.includes(passedHalls, 'B')) {
              var square = new fabric.Rect({ 
                width: 297, 
                height: 297, 
                left: 299, 
                top: 13, 
                fill: 'rgba(0,0,0,0.03)'
              });
              square.selectable = false
              canvas.add(square); 
            }
            if(!_.includes(passedHalls, 'C')) {
              var square = new fabric.Rect({ 
                width: 254, 
                height: 297, 
                left: 597, 
                top: 13, 
                fill: 'rgba(0,0,0,0.03)'
              });
              square.selectable = false
              canvas.add(square); 
            }
            if(!_.includes(passedHalls, 'D')) {
              var square = new fabric.Rect({ 
                width: 305, 
                height: 297, 
                left: 850, 
                top: 13, 
                fill: 'rgba(0,0,0,0.03)'
              });
              square.selectable = false
              canvas.add(square); 
            }
            
            
          }
          // fabric.Image.fromURL('http://fabricjs.com/assets/pug_small.jpg', function(myImg) {
          // //i create an extra var for to change some image properties
          // var img1 = myImg.set({ left: 3, top: 12 ,width:295,height:299});
          // canvas.add(square); 
          // });
    }

      const initCanvas = () => (
        new fabric.Canvas('floorplan_canvas', {
          height: 313,
          width: 1158,
          backgroundColor: 'white'
        })
       
        
      )

    

      const addRect = canvi => {
        const NewRect = new fabric.Rect({
          height: 52.5,
          width: 28,
          fill: '#fafafa',
          stroke : '#4e4e4e',
          strokeWidth : 1,
         // strokeDashArray: [2,2],
          snapAngle: 15
        });
        canvi.add(NewRect);
        canvi.renderAll();
      }

      const addCircle = canvi => {
        const NewCircle = new fabric.Circle({
          radius: 17.5,
          fill: '#fafafa',
          stroke : '#4e4e4e',
          strokeWidth : 1,
          strokeDashArray: [2,2]
        });
        canvi.add(NewCircle);
        canvi.renderAll();
      }

      const addText = canvi => {
        const newText = new fabric.IText('Label', {
            left: 50,
            top: 50,
            fontFamily: 'Helvetica',
            fontSize: '15',
            fill: '#1f1f1f',
            lineHeight: 1,
            snapAngle: 15
          });

          canvi.add(newText);
        canvi.renderAll();
      }

      const saveFloorplan = canvi => {
         // console.log(canvi.toJSON())
        //console.log(canvi.toDataURL('png'))

        let w = window.open('about:blank');
let image = new Image();
image.src = canvi.toDataURL('png');
setTimeout(function(){
  w.document.write(image.outerHTML);
}, 0);

     
      }

      const handleObjectRemoval = canvi => {
        canvi.remove(canvi.getActiveObject());
      }

    return (
        <>
        <Button
            onClick={() => {
                addRect(canvas)
            }}
            variant="outlined"
            style={{
                marginRight: "10px"
            }}
        >
Add Rect
        </Button>

        <Button
            onClick={() => {
                addCircle(canvas)
            }}
            variant="outlined"
            style={{
                marginRight: "10px"
            }}
        >
Add Cricle
        </Button>

        <Button
            onClick={() => {
                addText(canvas)
            }}
            variant="outlined"
            style={{
                marginRight: "10px"
            }}
        >
Add Text
        </Button>
        <Button
            onClick={() => {
                handleObjectRemoval(canvas)
            }}
            color="secondary"
            variant="outlined"
            style={{
                marginRight: "10px"
            }}
        >
Delete
        </Button>

        <Button
            onClick={() => {
                saveFloorplan(canvas)
            }}
            variant="contained"
            color="primary"
            disableElevation
            style={{
                marginRight: "10px"
            }}
        >
Save
        </Button>
<div

 style={{paddingTop: "10px"}}>

</div>

        <canvas id="floorplan_canvas" width="400" height="300" />
      </>
    )
}
