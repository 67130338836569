import React, {useState, useEffect} from 'react'
import {Table, TableContainer, TableRow, TableCell, TableHead,TableBody,  } from '@material-ui/core/';


   
      // const rows = [
      //   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
      //   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
      //   createData('Eclair', 262, 16.0, 24, 6.0),
      //   createData('Gingerbread', 356, 16.0, 49, 3.9),
      // ];

export default function EventPayments({passedPayments}) {

  const [eventPayments, setEventPayments] = useState([]);

  function createData(date_recvd, payment_type, payment_amount, payment_recvd_by) {
    return { date_recvd, payment_type, payment_amount, payment_recvd_by };
  }
  

  useEffect(() => {
    let eventPaymentsArray = [];
    if(passedPayments) {
      passedPayments.forEach(eventPayment => {
          eventPaymentsArray.push(createData(eventPayment.payment_date, eventPayment.payment_method, eventPayment.payment_amount, eventPayment.payment_received_by))
        });
    //  
    }
    setEventPayments(eventPaymentsArray)
  }, [passedPayments])

    return (
       <TableContainer >
      <Table aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell align="right">Method</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">Recv'd By</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {eventPayments.map((row) => (
            <TableRow key={row.date_recvd}>
              <TableCell component="th" scope="row">
                {row.date_recvd}
              </TableCell>
              <TableCell align="right">{row.payment_type}</TableCell>
              <TableCell align="right">${row.payment_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
              <TableCell align="right">{row.payment_recvd_by}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    )
}
